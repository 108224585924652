/* body{background-color: #eaeff1;} */
.error-message {
  color: #ff604f;
  border: #ff604f 1px solid;
  padding: 5px;
}

.error-message::before {
  display: inline;
  content: "⚠ ";
}

a {
  text-decoration: none;
}

.tand_icoon {
  position: relative;
}

.tand_icoon img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu-dialog img {
  width: 56px;
}

#root {
  position: relative;
  min-height: 95vh;
}

#error-page {
  display: flex;
  width: 800px;
  flex-direction: column;
  align-items: center;
  margin: auto;
}